import React, { useState, useEffect } from 'react';
import Logo from 'components/icons/Logo';
import TwoLinesLabelledInput from 'components/TwoLinesLabelledInput';
import { useAPIRequest } from 'dataProvider/postStateData/requestStateData';
import useData from 'hooks/useDataState';
import useTransition from 'hooks/useTransition';
import { useAuth } from 'dataProvider/authData';
import { usePathData } from 'dataProvider/pathData';
import { useRouteCallback } from 'hooks/useRouteCallbackHandler';
import { Redirect } from 'react-router-dom';
import { routeWebAdmin, routeWebUser } from 'utils/routes';
import { authTypeConstants } from 'constants/auth';
const otpLength = 6
export default function OtpPage(props) {
    const {section} = props
    const { setAuth, auth } = useAuth();
    const requestKey = `${section}.auth.otp.login`;
    const [serverValidationErrors, setServerValidationErrors] = useState(null);
    const token = sessionStorage.getItem(`${section}.otp.token`)
    const { clientId } = usePathData();
    const { addCallbackToUrl } = useRouteCallback();
    // 入力データ
    const [data, setData] = useData({ otp: '' });

    const handleChange = e => {
        if(e.target.name === 'otp') {
            const val = e.target.value
            if (/^\d{0,6}$/.test(val)) {
                setData({ ...data, [e.target.name]: val });
            }

        }else{
            setData({ ...data, [e.target.name]: e.target.value });
        }

        setError(false);
    };

    const removeToken = () => {
        sessionStorage.removeItem(token)
    }



    // Post
    const { post, states } = useAPIRequest();

    /**
     * Post Otp User Login
     * @return [fetch, state]
     */
    const handleLogin = () => {
        post(requestKey, '/auth/otp/login', {
            otp: data.otp,
            token: token
        }, x => x, {
            loading: 'ログイン処理中です',
            success: 'ログインが完了しました',
            error: 'ログインに失敗しました'
        })
        .then(res => {
            setAuth({
                token: res.data.data.access_token,
                authType: section,
                user: res.data.data.user,
                tokenExpiresIn: new Date().getTime() + res.data.data.expires_in * 1000, // in millisecond
            });
            removeToken()
        });
    }

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        if (states(requestKey).success) {
            setSuccess(true);
        } else if (states(requestKey).errors) {
            setServerValidationErrors(states(requestKey).errors);
        } else if (states(requestKey).error) {
            setError(true);
        } else {
            setError(false);
        }
    }, [states(requestKey).state]);

    // ページ遷移
    const { pushHistoryAbs } = useTransition();
    const moveToLoginPage = () => {
        removeToken()
        pushHistoryAbs('/login');
    };

    return auth.isAuthenticated && section === authTypeConstants.USER
            ? ( <Redirect to={routeWebUser(clientId, addCallbackToUrl('/'))} />)
            : auth.isAuthenticated && section === authTypeConstants.ADMIN
            ? (<Redirect to={routeWebAdmin(clientId, addCallbackToUrl('/'))} />)
    : (

        <div className="page login-template">
            <div className="card p-4">
                <Logo className="card-img-top logo icon-primary" />
                <h2 className="card-subtitle text-center h4">二要素認証</h2>
                <div className="card-body">
                    <div className="card-subtitle h6">
                        IDとして登録いただいたメールアドレスもしくは電話番号にワンタイムパスワードを送信しました。本文に記載されているワンタイムパスワードを入力し、本人認証を完了させてください。
                    </div>

                    {!token ? (
                        <div className="text-center">
                            ※URLが間違っている可能性があります。
                            <br />
                            再度ご確認ください。
                        </div>
                    ) : (
                        <React.Fragment>
                            <TwoLinesLabelledInput
                                label="ワンタイムパスワード"
                                name="otp"
                                value={data.otp}
                                onChange={handleChange}
                                pattern="\d{1,6}"
                            />
                            {serverValidationErrors?.otp && (
                                <div className="text-left mt-2">
                                    <small className="danger">{serverValidationErrors?.otp}</small>
                                </div>
                            )}
                            <div className="mt-5 text-center">
                                <button
                                    type="button"
                                    className="btn btn-block btn-primary px-4"
                                    onClick={handleLogin}
                                    disabled={states(requestKey).loading || data.otp.length !== otpLength}
                                >
                                    認証
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-block btn-outline-bluegray px-4"
                                    onClick={moveToLoginPage}
                                >
                                    戻る
                                </button>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
}
