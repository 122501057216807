// Note: This file content should be synced with laravel/app/Enums/Usage.php
export default {
    DisableName: 1,
    DisableNickname: 2,
    DisableUpload: 3,
    DisableInternalReport: 4,
    LimitCategoriesCount: 5,
    DisableCSV: 6,
    DisableIpRestriction: 7,
    DisableRoleManagement: 8,
    DisablePostUpdate: 9,
    DisableAddAdmin: 10,
    DisableInvitationByPhone: 11,
    DisableUserGroup: 12,
    DisablePostClose: 13,
    DisableAnouncementFunction: 14,
    DisableUserInvitationUrlFunction: 15,
    DisableGroupFilter: 16,
    DisableSecurityPolicy: 17,
};
