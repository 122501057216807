import React, { useState, useEffect } from 'react';
import Logo from 'components/icons/Logo';
import { useAPIRequest } from 'dataProvider/postStateData/requestStateData';
import {useLocation, useHistory} from 'react-router-dom';
const requestKey = 'password.send';
export default function PasswordSend() {
    const location = useLocation();
    const history = useHistory();
    const [errorMsg, setErrorMsg] = useState(null);

    const [id, setId] = useState('');
    const handleChange = e => {
        setError(false);
        setId(e.target.value);
    };

    const { post, states } = useAPIRequest();

    const [sentId, setSentId] = useState('');
    const sendPassword = () => {
        post(requestKey, '/auth/password/create', { id }, x => x, {
            loading: `パスワードを ${id} に送信しています`,
            success: `パスワードの送信を完了しました`,
            error: `パスワードの送信に失敗しました`,
        }).then(() => {
            setSentId(id);
        });
    };

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    useEffect(() => {
        if (states(requestKey).success) {
            setSuccess(true);
        } else if (states(requestKey).error) {
            setError(true);
        } else {
            setError(false);
        }
    }, [states(requestKey).state]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const errorMessage = queryParams.get('message');
        if (errorMessage) {
            setErrorMsg(errorMessage);

            // Remove the message parameter from the URL
            const newUrl = location.pathname + location.search.replace(/[\?&]message=[^&]*/, '');
            history.replace(newUrl);  // `replace` ensures we don't add a new entry to the history
        }

    }, [location, history])

    return (
        <div className="page login-template">
            <div className="card p-4">
                <Logo className="card-img-top logo icon-primary" />
                <div className="card-body">
                    {success ? (
                        <div className="text-center">
                            {sentId}に <br /> パスワードをリセットするためのURLを送信しました。
                            <br />
                            URLからパスワードの再設定を行ってください。
                        </div>
                    ) : (
                        <React.Fragment>
                            {errorMsg && <div className="p-3 mb-4 bold danger" style={{backgroundColor: '#FCEEF1'}}>{errorMsg}</div>}
                            <label htmlFor="email-input">
                                登録しているメールアドレスまたは電話番号を入力してください。
                            </label>
                            <input
                                type="text"
                                id="email-input"
                                className="form-control"
                                name="email"
                                value={id}
                                onChange={handleChange}
                            />
                            {error && (
                                <small className="danger mb-2">
                                    {states(requestKey).errorMessage}
                                </small>
                            )}
                            <div className="mt-4 text-center">
                                <button
                                    type="button"
                                    className="btn btn-block btn-secondary px-4"
                                    onClick={sendPassword}
                                    disabled={id === '' || states(requestKey).loading}
                                >
                                    リセット用のリンクを送信
                                </button>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
}
